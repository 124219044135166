<template>
	<div class="p-4 mx-auto text-center">
		<h2 class="text-2xl font-bold">
			What type of business are you?
		</h2>
		<p class="max-w-sm py-2 mx-auto">
			No matter what type of store or business you are, Where's Weed can help you stand out.
		</p>
		<div class="flex flex-wrap -mx-2">
			<router-link
				v-for="(businessType, index) in BUSINESS_TYPES"
				:key="index"
				class="w-full p-2 md:w-1/2"
				:to="{ name: 'tourFeatures', params: { businessType: businessType.url }}"
			>
				<div class="flex flex-col items-center justify-center p-8 border rounded shadow hover:shadow-lg">
					<img
						class="w-8 h-8"
						:src="requireIcon(businessType.icon)"
						alt="Business Type"
					>
					<h3 class="font-semibold">
						{{ businessType.label }}
					</h3>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script async>
import { BUSINESS_TYPES } from '@/constants/tour/index.js'
import requireIcon from '@/utils/requireIcon.js'

export default {
	data: function () {
		return {
			BUSINESS_TYPES
		}
	},
	methods: {
		requireIcon: requireIcon
	}
}
</script>
