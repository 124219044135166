const phoneFormat = function (value) {
	if (!value) return ''
	if (value.substring(0, 1) == '(') return value
	const valueSansDashes = value.replaceAll('-', '')
	const areaCode = valueSansDashes.substring(0, 3)
	const middle = valueSansDashes.substring(3, 6)
	const end = valueSansDashes.substring(6, 10)

	const formatted = '(' + areaCode + ') ' + middle + '-' + end

	return formatted
}

export default phoneFormat
