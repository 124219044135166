<template>
	<div>
		<label>
			<input
				type="radio"
				name="duplicate"
				:value="business.listing_id"
			>
			<span class="dup-wrap">
				<h2>
					{{ business.name }}
				</h2>
				<div class="detail">
					{{ formattedAddress }}
				</div>
				<div class="detail">
					{{ formattedPhone }}
				</div>
			</span>
		</label>
	</div>
</template>

<script async>
import formatAddress from '@/utils/formatAddress.js'
import formatPhone from '@/utils/formatPhone.js'

export default {
	props: {
		business: {
			type: Object,
			required: true
		}
	},
	computed: {
		formattedAddress() {
			return formatAddress(this.business)
		},
		formattedPhone() {
			return formatPhone(this.business?.phone)
		}
	}
}
</script>
