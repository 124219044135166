<template>
	<DefaultLayout class="tour_shell">
		{{ testProp }}!?
		<router-view />
	</DefaultLayout>
</template>

<script async>
import { mapActions } from 'vuex'

import DefaultLayout from '@/layouts/default/Index.vue'
import businessSignupModule from '@/store/modules/businessSignupModule.js'

const routeToFunctionMap = {
	tourDuplicates: 'getDuplicates',
	tourFeatures: 'get',
	tourPackages: 'getPackages'
}

export default {
	metaInfo: {
		title: 'Business Solutions for Dispensaries | Add your Dispensary to Where\'s Weed',
		meta: [ {
			name: 'description',
			content: 'Grow your dispensary business with Where’s Weed solutions for Online Pre-Ordering, Digital Advertising, Point of Sale, Cannabis Marketing, Deals & Promotions'
		}
		]
	},
	components: {
		DefaultLayout
	},
	beforeRouteUpdate: function (to, from, next) {
		this.fetchData(to)
		next()
	},
	props: {
		testProp: {
			type: String,
			default: ''
		}
	},

	beforeCreate: function () {
		this.$store.registerModule('businessSignup', businessSignupModule)
	},
	serverPrefetch: function() {
		return this.fetchData(this.$route)
	},
	created: function() {
		this.fetchData(this.$route)
	},
	methods: {
		...mapActions([ 'apiCall' ]),
		...mapActions('businessSignup', [ 'SET' ]),
		fetchData: async function (routeTo) {
			if (!routeTo.params.signup) return // if no signup hash was passed then no need to hit the api

			const getData = {
				func_name: routeToFunctionMap[routeTo.name],
				data: { signup: routeTo.params.signup },
				type: 'BusinessSignup'
			}
			const response = await this.apiCall(getData)

			this.SET(response.data.results)
			return response
		}
	}
}
</script>
