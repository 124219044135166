<template>
	<div>
		<component :is="selectedComponent" />
		<div
			id="signup"
			class="absolute mt-16"
		/>
		<form
			class="grid max-w-2xl grid-cols-1 gap-6 px-4 py-16 mx-auto xl:px-0"
			@submit.prevent="signupFormSubmit"
		>
			<div class="flex flex-wrap">
				<h3 class="w-full pb-6 mb-6 text-2xl font-bold text-center border-b border-gray-200">
					Add your {{ tourType.label }} to {{ APP_META.NAME }}
				</h3>
				<h4 class="w-full pb-4 text-xl font-bold text-left text-black">
					Your Contact Information
				</h4>
				<div class="grid w-full grid-cols-1 text-left gap-x-12 md:grid-cols-2">
					<div
						v-for="field in signupData.contactFields"
						:key="field.id"
						class="w-full mb-4"
					>
						<WwTextInput
							v-if="field.type === TEL"
							:id="field.id"
							v-model="field.value"
							:value="field.value"
							:input-type="TEL"
							:label="field.label"
							input-class="w-full"
							:required="field.required"
							:autocomplete="field.autocomplete"
							:state="contactPhoneInputState"
							@validity-changed="handleContactPhoneValidation"
						/>
						<WwTextInput
							v-else
							:id="field.id"
							v-model="field.value"
							:value="field.value"
							:input-type="field.type"
							:label="field.label"
							input-class="w-full"
							:required="field.required"
							:autocomplete="field.autocomplete"
							:state="inputFieldState(field.value, field.type)"
						/>
					</div>
				</div>
			</div>
			<div
				id="partners"
				class="flex flex-wrap"
			>
				<h4 class="pb-4 text-xl font-bold text-left">
					Business Information
				</h4>
				<div class="grid w-full grid-cols-1 pb-6 text-left gap-x-12 md:grid-cols-2">
					<div
						v-for="field in signupData.businessFields"
						:key="field.id"
						class="w-full mb-3"
					>
						<WwTextInput
							v-if="field.type === TEL"
							:id="field.id"
							v-model="field.value"
							:value="field.value"
							:input-type="TEL"
							:label="field.label"
							input-class="w-full"
							:required="field.required"
							:autocomplete="field.autocomplete"
							:state="businessFormPhoneInputState"
							@validity-changed="handleBusinessPhoneValidation"
						/>
						<div
							v-else-if="field.type === SELECT"
							class="mt-1"
						>
							<WwLabel class="text-sm text-left">
								{{ field.label }}
								<span
									v-if="field.required"
									class="text-red-500 lowercase"
								>
									*
								</span>
							</WwLabel>
							<select
								v-if="field.type === SELECT && field.id === 'state'"
								v-model="field.value"
								class="w-full border-2 border-gray-300 rounded-md focus:ring focus:ring-green-300 h-11"
							>
								<option value="">
									&nbsp;
								</option>
								<option
									v-for="({ name }, key) in states"
									:key="key"
									:value="key"
								>
									{{ name }}
								</option>
							</select>
							<select
								v-else
								v-model="field.value"
								class="w-full border-2 border-gray-300 rounded-md focus:ring focus:ring-green-300 h-11"
							>
								<option
									v-if="field.id === 'pos'"
									disabled
									value=""
								>
									Select a POS System
								</option>
								<option
									v-for="option in field.options"
									:key="option.value"
									:value="option.value"
								>
									{{ option.label }}
								</option>
								<option
									v-if="field.id === 'pos'"
									value="other"
								>
									Other
								</option>
							</select>
							<WwTextInput
								v-if="field.value === 'other'"
								:id="field.id"
								v-model="otherPos"
								placeholder="Other Point of Sale"
								input-class="w-full"
								:autocomplete="field.autocomplete"
								:state="inputFieldState(otherPos, TEXT)"
							/>
						</div>
						<WwTextInput
							v-else
							:id="field.id"
							v-model="field.value"
							:value="field.value"
							:label="field.label"
							:input-type="field.type"
							input-class="w-full"
							:required="field.required"
							:autocomplete="field.autocomplete"
							:state="inputFieldState(field.value, field.type)"
						/>
					</div>
				</div>
				<div class="w-full">
					<WwSubmitButton
						:disabled="!formIsValid"
						:is-loading="isLoading"
						button-text="Add Business"
					/>
				</div>
			</div>
		</form>
	</div>
</template>

<script async>
import { mapActions } from 'vuex'

import WwSubmitButton from '@/components/UI/buttons/WwSubmitButton.vue'
import WwLabel from '@/components/UI/helpers/WwLabel.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import { APP_META } from '@/constants/index.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, NUMBER, TEL, TEXT, ZIP } from '@/constants/text-inputs/types.js'
import { BUSINESS_TYPES, PARTNERS } from '@/constants/tour/index.js'
import statesMap from '@/etc/generated/states.json'
import { logError } from '@/utils/error-handling.js'
import { inputFieldState } from '@/validators/text-inputs.js'

const SELECT = 'select'

export default {
	name: 'TourFeatures',
	components: {
		WwLabel,
		WwTextInput,
		WwSubmitButton
	},
	data() {
		return {
			APP_META,
			states: statesMap,
			dataLoaded: false,
			PARTNERS,
			BUSINESS_TYPES,
			signupData: {
				signup: this.$route.params.signup,
				contactFields: [
					{ label: 'First Name', value: '', id: 'contact_first_name', required: true, type: TEXT, autocomplete: 'given-name' },
					{ label: 'Last Name', value: '', id: 'contact_last_name', required: true, type: TEXT, autocomplete: 'family-name' },
					{ label: 'Phone Number', value: '', id: 'contact_phone', required: true, type: TEL, autocomplete: 'tel' },
					{ label: 'Email', value: '', id: 'contact_email', required: true, type: EMAIL, autocomplete: 'email' }
				],
				businessFields: [
					{ label: 'Business Type', value: '', id: 'type', required: true, type: SELECT, options: BUSINESS_TYPES, autocomplete: 'on' },
					{ label: 'Business Name', value: '', id: 'name', required: true, type: TEXT, autocomplete: 'on' },
					{ label: 'Address 1', value: '', id: 'address', required: true, type: TEXT, autocomplete: 'address-line1' },
					{ label: 'Address 2', value: '', id: 'address2', required: false, type: TEXT, autocomplete: 'address-line2' },
					{ label: 'City', value: '', id: 'city', required: true, type: TEXT, autocomplete: 'address-level2' },
					{ label: 'State', value: '', id: 'state', required: true, type: SELECT, options: this.states, autocomplete: 'address-level1' },
					{ label: 'Zip Code', value: '', id: 'zip', required: true, type: ZIP, autocomplete: 'postal-code' },
					{ label: 'Company Website', value: '', id: 'website', required: false, type: TEXT, autocomplete: 'url' },
					{ label: 'Company Phone', value: '', id: 'phone', required: true, type: TEL, autocomplete: 'tel' },
					{ label: 'Company Email (private)', value: '', id: 'email', required: true, type: EMAIL, autocomplete: 'email' },
					{ label: 'Cannabis License', value: '', id: 'license', required: true, type: TEXT, autocomplete: 'on' },
					{ label: 'Point of Sale', value: '', id: 'pos', required: false, type: SELECT, options: PARTNERS, autocomplete: 'on' }
				]
			},
			otherPos: '',
			TEXT,
			EMAIL,
			NUMBER,
			TEL,
			SELECT,
			businessTypes: [
				{ label: 'Dispensary', value: 'dispensary' },
				{ label: 'Delivery Service', value: 'delivery' },
				{ label: 'Doctor', value: 'clinic' },
				{ label: 'Smoke Shop', value: 'smoke' }
			],
			INPUT_STATES,
			contactPhoneValid: false,
			businessPhoneValid: false,
			inputFieldState
		}
	},
	computed: {
		formIsValid() {
			return !this.signupData.contactFields
				.filter(field => field.required)
				.some(field => inputFieldState(field.value, field.type) === INPUT_STATES.ERROR) &&
				!this.signupData.businessFields
					.filter(field => field.required)
					.some(field => inputFieldState(field.value, field.type) === INPUT_STATES.ERROR) &&
				this.contactPhoneInputState === INPUT_STATES.SUCCESS &&
				this.businessFormPhoneInputState === INPUT_STATES.SUCCESS
		},
		tourType: function () {
			try {
				return BUSINESS_TYPES.find((item) => item.url === this.$route.params.businessType)
			} catch (e) {
				logError(e)
				return null
			}
		},
		selectedComponent: function () { // NOTE this is for the content per business type.  This should be moved to db driven probably at some point
			return () => import(`./components/${this.tourType.component}`)
		},
		contactPhoneInputState() {
			if (!this.signupData.contactFields?.find(field => field.id === 'contact_phone')?.value.length) {
				return INPUT_STATES.ERROR
			}
			return this.contactPhoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		businessFormPhoneInputState() {
			if (!this.signupData.businessFields?.find(field => field.id === 'phone')?.value.length) {
				return INPUT_STATES.ERROR
			}
			return this.businessPhoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formData() {
			const obj = { signup: this.signupData.signup }
			const assignField = (field) => { obj[field.id] = field.value }
			this.signupData.contactFields.forEach(field => assignField(field))
			this.signupData.businessFields.forEach(field => assignField(field))
			obj.state = this.states[this.signupData.businessFields.find(field => field.id === 'state').value]?.id
			if (this.otherPos.length) { obj.pos = this.otherPos }
			return obj
		}
	},
	mounted() {
		try {
			this.signupData.businessFields.find(field => field.id === 'type').value = this.tourType.value || this.tourType.url
			this.restorePrefillData()
		} catch (e) {
			logError(e)
		}
	},
	methods: {
		...mapActions([ 'newPost' ]),
		signupFormSubmit: async function () {
			const params = {
				...this.formData
			}
			const endpoint = 'BusinessSignup/signup'

			try {
				const response = await this.newPost({
					endpoint, params, store: this.$store
				})

				if (response.data.success) {
					localStorage.clear('businessPrefillData')
				}
				if (response.data.results?.redirect) {
					this.$router.push(response.data.results.redirect)
				}
			} catch (err) {
				// console.error(err)
			}
		},
		restorePrefillData() {
			if (localStorage.getItem('businessPrefillData')) {
				const {
					type,
					name,
					address,
					address2,
					city,
					state,
					zip,
					website,
					phone,
					email
				} = JSON.parse(localStorage.getItem('businessPrefillData'))
				this.signupData.businessFields.find(field => field.id === 'type').value = type
				this.signupData.businessFields.find(field => field.id === 'name').value = name
				this.signupData.businessFields.find(field => field.id === 'address').value = address
				this.signupData.businessFields.find(field => field.id === 'address2').value = address2
				this.signupData.businessFields.find(field => field.id === 'city').value = city
				this.signupData.businessFields.find(field => field.id === 'state').value = state
				this.signupData.businessFields.find(field => field.id === 'website').value = website
				this.signupData.businessFields.find(field => field.id === 'zip').value = zip
				this.signupData.businessFields.find(field => field.id === 'phone').value = phone
				this.signupData.businessFields.find(field => field.id === 'email').value = email
			}
		},
		fieldState(value) {
			return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		handleContactPhoneValidation(payload) {
			this.contactPhoneValid = payload
		},
		handleBusinessPhoneValidation(payload) {
			this.businessPhoneValid = payload
		}
	}
}
</script>
