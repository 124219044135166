import statesById from '@/etc/generated/statesById.json'

const formatAddress = function (value) {
	if (!value.address) return ''
	return `${value.address}, ${(value.address2 ? value.address2 + ', ' : '')}${(value.city ? value.city + ', ' : '')}${statesById.items[value.state].short}`
}

export const formatAddressFromGql = (value) => {
	if (!value) return ''
	return `${value.street ? value.street + ', ' : ''}${value.city ? value.city + ', ' : ''}${value.state?.short ? value.state.short + ' ' : ''}${value.zip ? value.zip : ''}`
}

export default formatAddress
