<template>
	<div class="mt-16 flex flex-col">
		<h1 class="text-3xl">
			Thank You
		</h1>
		Thanks, you have been put on our business claim wait list.
		<br>
		A representative will contact you shortly.
	</div>
</template>

<script async>
export default {
	mounted () {
		if (!this.$store.state.auth.loggedIn) {
			this.$router.push('/')
		}
	}
}
</script>
