<template>
	<div class="tour-wrap">
		<div class="max-w-6xl p-4 mx-auto duplicates">
			<div class="flex flex-wrap p-4">
				<h1 class="text-4xl font-bold">
					Possible Matches
				</h1>
				<div class="py-6">
					<p class="text-xl">
						Here are some possible matches based on the information you provided.
					</p>
					<p class="pb-3 text-lg">
						If you see your business listed, please select the listing and click "Continue".
					</p>
					<p class="text-lg">
						Otherwise, please select "None of these businesses are mine".
					</p>
				</div>
				<form
					ref="duplicatesForm"
					v-require-login="{callback: submitForm}"
					class="max-w-2xl mx-auto"
				>
					<div class="duplicate-list">
						<ul>
							<li
								v-for="(duplicateId, index) in signupDuplicates.ids"
								:key="index"
							>
								<TourDuplicate
									:business="signupDuplicates.items[duplicateId]"
								/>
							</li>
						</ul>
					</div>
					<div class="mb-3 none">
						<label>
							<h2>
								<input
									v-invalid="`Please make a selection.`"
									type="radio"
									name="duplicate"
									value="0"
									required
								>None of these listings are my business
							</h2>
						</label>
					</div>
					<div class="save">
						<button
							class="px-3 py-2 text-white bg-green-500 rounded-lg"
							type="submit"
						>
							Continue
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapState } from 'vuex'

import TourDuplicate from './components/Duplicate.vue'

export default {
	components: {
		TourDuplicate
	},
	data: function () {
		return {
			postData: { signup: this.$route.params.signup }
		}
	},
	computed: {
		...mapState('businessSignup', {
			businessSignupData: state => state.data,
			signupDuplicates: state => state.duplicates
		})
	},
	watch: {
		signupDuplicates: function () {
		}
	},
	methods: {
		...mapActions('business', [
			'getBusinessSignup'
		]
		),
		...mapActions([ 'newPost' ]),
		submitForm: async function () {
			const params = { signup: this.postData.signup, duplicate: this.$refs.duplicatesForm.duplicate.value }
			const endpoint = 'BusinessSignup/saveDuplicates'

			const response = await this.newPost({
				endpoint, params, store: this.$store
			})
			const error = response.error || response.data.error || 'Unknown error'

			if (response.data.results.redirect) {	// was a successful save
				setTimeout(() => { this.$router.push({ name: 'tourThankYou' }) }, 500)
			} else {
				this.$store.dispatch('setError', error)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	:deep(.duplicates) {
		text-align: center;
		.row {
			max-width: 650px;
			margin: 0 auto;
		}
		h1 {
			display: inline-block;
			width: 100%;
			font-size: 2em;
			font-weight: 700;
			text-align: center;
		}
		p {
			text-align: center;
			display: inline-block;
			width: 100%;
		}
		.duplicate-list {
			display: inline-block;
			width: 100%;

			h2 {
				font-size: 1.25em;
				margin: 0;
				padding: 0;
				font-weight: 700;
				color: black;
				input {
					margin-right: 15px;
				}
			}
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
			}
			li {

				position: relative;
				text-align: left;
				margin-bottom: 15px;
				input {
					position: absolute;
					left: 30px;
					top: 30%;
					width: 30px;
					height: 30px;
					visibility: hidden;
				}
				input:checked + span.dup-wrap {
					border: 2px solid $primary1;
					display: inline-block;
					width: 100%;
				}
				.dup-wrap {
					cursor: pointer;
					display: inline-block;
					width: 100%;
					padding: 20px;
					border: 2px solid $gray5;
					box-shadow: 0 6px 14px rgba(233, 236, 240, 0.6);
					border-radius: 8px;
				}
				label {
					display: inline-block;
					width: 100%;
				}
			}

			.detail {
				font-size: 0.85em;
			}
		}
		.none {
			display: inline-block;
			width: 100%;
			border: 2px solid $gray5;
			box-shadow: 0 6px 14px rgba(233, 236, 240, 0.6);
			border-radius: 8px;
			text-align: left;

			h2 {
				font-weight: 700;
				font-size: 1.25em;
				margin: 0;
				padding: 0;
				color: black;
				input {
					margin-right: 15px;
				}
			}
			label {
				cursor: pointer;
				display: inline-block;
				width: 100%;
				padding: 30px;
			}
		}
		.save {
			button {
				font-weight: 600;
				text-transform: uppercase;
				margin: 10px 0;
				width: 100%;
				display: inline-block;
				padding: 10px;
			}
		}
	}
</style>
