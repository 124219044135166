const initialState = () => {
	return {
		data: {},
		duplicates: {}
	}
}

const businessSignup = {
	namespaced: true,
	state: initialState,
	mutations: {
		setSignup: function (state, data) {
			state.data = Object.assign({}, data)
		},
		setDuplicates: function (state, data) {
			state.duplicates = Object.assign({}, data)
		}
	},
	actions: {
		SET: function (context, data) {
			if (data.signup) {
				context.commit('setSignup', data.signup)
			}
			if (data.duplicates) {
				context.commit('setDuplicates', data.duplicates)
			}
		}
	},
	getters: { // getters give us an easy way to grab this data in components and is more explicit about what we plan to use

	}
}

export default businessSignup
